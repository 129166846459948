<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="8" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('title')}" label="Title">
							<b-form-input v-model="title" v-validate="'required'" data-vv-name="title">
							</b-form-input>
							<div v-if="errors.has('title')">
								<p class="text-danger">{{ errors.first('title') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('slug')}" label="Slug">
							<b-form-input disabled v-model="models.slug" v-validate="'required'" data-vv-name="slug">
							</b-form-input>
							<div v-if="errors.has('slug')">
								<p class="text-danger">{{ errors.first('slug') }}</p>
							</div>
						</b-form-group>

						<div class="my-3">
							<uploader :value="models.galleries" @get-files="files => models.galleries = files"/>
						</div>

						<b-form-group v-bind:class="{ 'is-invalid': errors.has('description')}" label="Description">
							<Editor :value="models.description" @input="value => models.description = value"/>
							<div v-if="errors.has('description')">
								<p class="text-danger">{{ errors.first('description') }}</p>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="4" class="mb-2">
						<div class="image-input">
							<b-img :src="getUrlOfFile(models.thumbnail, true)" class="mb-2" style="max-height: 160px; border: none"/>
							<b-button class="btn-upload btn-block" @click="$refs.filemanager.show('thumbnail')">
									<i class="fa fa-upload"></i> Upload Thumbnail
							</b-button>
						</div>
						<b-form-group class="mt-3" v-bind:class="{ 'is-invalid': errors.has('thumbnail title')}" label="Thumbnail Title">
							<b-form-input v-model="models.thumbnail_title" v-validate="vars.thumbnailTitleRules" data-vv-name="thumbnail title">
							</b-form-input>
							<div v-if="errors.has('thumbnail title')">
								<p class="text-danger">{{ errors.first('thumbnail title') }}</p>
							</div>
						</b-form-group>
						<b-form-select class="my-3" v-model="models.category_id" :options="masters.categories"></b-form-select>
						<div id="datepicker-date-wrapper">
							<label for="datepicker-date">Date</label>
							<input class="d-block w-100 mb-3 p-2" style="border: 1px solid #e8e8e8;" type="datetime-local" id="date" name="date" v-model="models.date">
						</div>
						<div id="datepicker-publishAt-wrapper">
							<label for="datepicker-publishAt">Publish At</label>
							<input class="d-block w-100 mb-3 p-2" style="border: 1px solid #e8e8e8;" type="datetime-local" id="publish_at" name="publish_at" v-model="models.publish_at" :min="vars.minDate">
						</div>
						<b-form-textarea
							class="mb-3"
							v-model="models.meta.keyword"
							placeholder="Meta Keyword"
							rows="5"
							max-rows="10"
						></b-form-textarea>
						<b-form-textarea
							class="mb-3"
							v-model="models.meta.description"
							placeholder="Meta Description"
							rows="5"
							max-rows="10"
						></b-form-textarea>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('tag')}" label="Tag">
							<multiselect 
								style="margin-left: -10px" 
								class="bg-white mb-4" 
								tag-placeholder="" 
								placeholder="Tag" 
								:showPointer="false"
								v-model="masters.tags"
								:showNoResults="false"
								:options="masters.tags" 
								hideSelected
								multiple
								taggable
								:closeOnSelect="false"
								@tag="addTag">
							</multiselect>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
		<Filemanager ref="filemanager" name="news" @selectMedia="selectMedia"/>
	</form>
</template>
<script>
	import api from '@/utils/api.js'
	import moment from 'moment'
	import {
		getUrlOfFile,
		str_slug
	} from '@/utils/helpers.js';
	import Editor from '@/components/editor';
	import Filemanager from '@/components/file_manager';
	import ResponseAlert from '@/components/response_alert';
	import Uploader from '@/components/uploader.vue';
	
	export default {
		name: 'FormVisitor',
		components: {
			ResponseAlert,
			Filemanager,
			Editor,
			Uploader
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					type: 'article',
					title: '',
					slug: '',
					category_id: null,
					date: '',
					publish_at: '',
					thumbnail: '',
					thumbnail_title: '',
					description: '',
					galleries: [],
					is_contributor_news: true,
					meta: {
							description:"",
							keyword: "",
							tag:""
					},
					status: "waiting review",
					advertisement_id: null,
					is_breaking_news: false
				},
				
				masters: {
					categories: [
						{
							value: null,
							text: 'Select Category'
						}
					],
					tags: []
				},
				vars: {
					minDate: moment().format('YYYY-MM-DDTHH:mm'),
					thumbnailTitleRules: '',
				},
				moment,
				getUrlOfFile
			}
		},
		created() {
			let _ = this
			_.getById();
			_.getCategories();
		},
		computed: {
			title: {
				get(){
					return this.models.title
				},
				set(value){
					this.models.title = value
					this.models.slug = str_slug(value)
				}
			},
			thumbnail() {
				return this.models.thumbnail
			},
		},
		watch: {
			thumbnail() {
				this.vars.thumbnailTitleRules = this.thumbnail === '' ? '' : 'required'
			}
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.vars.minDate = ''
					_.$axios.get(`${api.news}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							if(data.status === 'waiting review') {
								_.models.title = data.title,
								_.models.slug = data.slug,
								_.models.category_id = data.category_id,
								_.models.date = moment(data.date).format('YYYY-MM-DDTHH:mm'),
								_.models.publish_at = moment(data.publish_at).format('YYYY-MM-DDTHH:mm'),
								_.models.thumbnail = data.thumbnail,
								_.models.galleries = data.galleries,
								_.models.thumbnail_title = data.thumbnail_title,
								_.models.description = data.description,
								_.models.is_contributor_news = data.is_contributor_news,
								_.models.meta.description = data.meta.description
								_.models.meta.keyword = data.meta.keyword
								_.models.status = data.status
								_.masters.tags = data.meta.tag === '' ? [] : data.meta.tag.split(",")
							} else {
								_.$router.push({
									name: _.config.uri
								})
							}
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
			getCategories() {
				const _ = this
				_.$axios.get(api.news_category, {
					params: {
						status: 'active',
						orderby: 'name',
						sort: 'asc',
						is_available_contributor: true
					}
				}).then(res => {
					const {rows} = res.data.data
					rows.forEach(row => {
						_.masters.categories.push({
							value: row.id,
							text: row.name
						})
					})
				});
			},
			selectMedia(field, media) {
				const _ = this;
				_.models[field] = media.file;
				_.models[`${field}_title`] = media.title
			},
			addTag (newTag) {
				this.masters.tags.push(newTag)
			},
			save() {
				const _ = this;
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						let message = "create"

						_.models.meta.tag = _.masters.tags.join(',')

						if (!_.$route.params.id) {
							request = _.$axios.post(api.news, _.models);
						} else {
							message = "update"
							request = _.$axios.put(`${api.news}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => {
								this.$showToast('Success', `Success ${message} article`)
								_.$router.push({
									name: _.config.uri
								})
							})
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		}
	}
</script>